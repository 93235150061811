import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { login, logoutSilent, profileInit } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { AccessToken, AuthState, AuthnTransaction, OktaAuth, TokenResponse, toQueryString, JWTObject } from '@okta/okta-auth-js';
import { from, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, take, tap, withLatestFrom, map, switchMap } from 'rxjs/operators';
import { Language } from '@nx-bundesliga/models';
import { getWorkingLanguage, getAuthenticated, getWorkingProfile } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { environment } from '@nx-bundesliga/bundesliga-com/environment';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { Profile } from '@nx-bundesliga/models';

@Injectable({ providedIn: 'root' })
export class OktaAuthService implements OnDestroy {
	private redirectUri = this.window.location.origin + '/account/callback';
	private clientId = environment.okta.clientId;
	// TODO: read okta config somewhere out of a central configuration
	private oktaAuth: OktaAuth;
	private isBrowser: boolean;
	private isWebview = false;

	public product: 'web' | 'fantasy' | 'bundesliga-app' = 'web';
	public windowObjectAuthorizedSubs: Function[] = [];
	public windowObjectTokensSubs: Function[] = [];
	public windowObject = {
		authorized: false,
		subscribeAuthorized: (fn) => {
			this.windowObjectAuthorized(fn);
		},
		tokens: {
			accessToken: null,
			idToken: null,
			refreshToken: null
		},
		subscribeTokens: (fn) => {
			this.windowObjectTokens(fn);
		},
		loginByAccessToken: this.loginByAuthHeader.bind(this)
	};

	constructor(private router: Router, private config: ConfigService, private http: HttpClient, private readonly lstore: Store<Language>, private readonly pstore: Store<Profile>, private zone: NgZone, @Inject(WINDOW) private window: any, @Inject(PLATFORM_ID) platformId: Object) {
		this.isWebview = environment.webview;
		this.isBrowser = isPlatformBrowser(platformId);
		if (this.isBrowser) {
			this.oktaAuth = new OktaAuth({
				issuer: environment.okta.issuer,
				clientId: this.clientId,
				redirectUri: this.redirectUri,
				pkce: true,
				tokenManager: {
					storage: 'localStorage',
					syncStorage: true,
					autoRemove: !environment.webview,
					autoRenew: !environment.webview
					// expireEarlySeconds: 180
				},
				storageManager: {
					token: {
						storageTypes: ['localStorage']
					},
					cache: {
						storageTypes: ['localStorage']
					},
					transaction: {
						storageTypes: ['localStorage']
					}
				},
				devMode: environment.build === 'localhost',
				transformAuthState: async (oktaAuth, authState) => {
					const accessToken = await this.oktaAuth.tokenManager.get('accessToken');
					authState.isAuthenticated = !!accessToken;
					if (accessToken && accessToken.hasOwnProperty('claims')) {
						authState = Object.assign(authState, accessToken['claims']);
					}
					return authState;
				}
			});
			this.pstore.dispatch(profileInit());
			this.window['SSO'] = this.windowObject;
			this.oktaAuth.start();
			this.oktaAuth.authStateManager.subscribe((authState: AuthState) => {
				const tokens = {
					accessToken: authState?.accessToken || null,
					idToken: authState?.idToken || null,
					refreshToken: authState?.refreshToken || null
				};
				const isAuthenticated = authState?.isAuthenticated || false;
				if (isAuthenticated) {
					this.pstore.dispatch(
						login({
							tokens: {
								accessToken: authState?.accessToken?.accessToken || null,
								idToken: authState?.idToken?.idToken || null,
								refreshToken: authState?.refreshToken?.refreshToken || null
							},
							claims: authState?.accessToken?.claims
						})
					);
				} else {
					// only force logout when actually previously logged in
					this.pstore
						.pipe(
							select(getAuthenticated),
							take(1),
							tap((auth: boolean) => {
								if (auth) {
									this.pstore.dispatch(logoutSilent());
								}
							})
						)
						.subscribe();
				}
				this.fireWindowObjectAuthorized(isAuthenticated);
				this.fireWindowObjectTokens(tokens);
			});
			this.oktaAuth.authStateManager.updateAuthState();
		}
	}

	public windowObjectAuthorized(fn: Function) {
		this.windowObjectAuthorizedSubs.push(fn);
	}

	public windowObjectTokens(fn: Function) {
		this.windowObjectTokensSubs.push(fn);
	}

	public fireWindowObjectAuthorized(auth: boolean) {
		this.windowObject.authorized = auth;
		this.windowObjectAuthorizedSubs.forEach((fn) => {
			fn(null, auth);
		});
	}

	public fireWindowObjectTokens(tokens: any) {
		this.windowObject.tokens = tokens;
		this.windowObjectTokensSubs.forEach((fn) => {
			fn(null, tokens);
		});
	}

	getProfileInfo() {
		const restUrl = `${environment.okta.restUrl}/profile`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((profile) => profile?.tokens?.accessToken),
			switchMap((token: string) => this.http.get(restUrl, { headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }) }))
		);
	}

	handleAuthentication(product = ''): Observable<any> {
		if (this.isBrowser) {
			return from(this.oktaAuth.token.parseFromUrl()).pipe(
				withLatestFrom(
					this.lstore.pipe(
						select(getWorkingLanguage),
						map((lang: Language) => lang.code)
					)
				),
				tap(([tokenContainer, language]: [TokenResponse, string]) => {
					this.oktaAuth.tokenManager.setTokens({
						'idToken': tokenContainer.tokens.idToken,
						'accessToken': tokenContainer.tokens.accessToken,
						'refreshToken': tokenContainer.tokens.refreshToken
					});
					this.oktaAuth.authStateManager.updateAuthState();

					const claims = tokenContainer.tokens.accessToken.claims;
					const registrationComplete = claims.hasOwnProperty('DFL_registrationComplete') && claims['DFL_registrationComplete'] && claims.hasOwnProperty('DFL_isVerified') && claims['DFL_isVerified'];
					const restoreLogin = this.getLoginData();
					if (registrationComplete === false) {
						const routingRegister = [language, 'account', 'register'];
						if (this.isWebview && product && product === 'web') {
							product = 'fantasy'; // there is no product web on webview. it's either bundesliga-app or fantasy. And fantasy was first without any parameter, so default is fantasy
						}
						this.router.navigate(this.isWebview ? (product && product !== '' ? [...routingRegister, product] : routingRegister) : [language, 'bundesliga', 'account', 'register'], {
							replaceUrl: true,
							queryParams: { ...(restoreLogin?.queryParams || {}), 'completeRegistration': 'pending' },
							queryParamsHandling: 'merge'
						});
					} else {
						if (this.isWebview && restoreLogin?.keeploggedin && restoreLogin?.codeChallenge && restoreLogin?.redirectUri) {
							this.signInFromSession(restoreLogin.keeploggedin, restoreLogin.codeChallenge, restoreLogin.redirectUri);
						} else {
							const canRedirect = this.redirectToTargetUrlFromLocalStorage();
							if (!canRedirect) {
								this.router.navigate(this.isWebview ? [language, 'account', 'login'] : [language, 'bundesliga', 'account', 'login'], { replaceUrl: true });
							}
						}
					}
				}),
				catchError(this.handleError)
			);
		}
		return of(false);
	}

	redirectToTargetUrlFromLocalStorage(): boolean {
		if (!this.isWebview && this.isBrowser === true && this.window?.localStorage) {
			const targetUrlAfterSingIn = this.window.localStorage.getItem('targetUrlAfterSingIn');
			if (targetUrlAfterSingIn && targetUrlAfterSingIn !== '') {
				this.window.localStorage.removeItem('targetUrlAfterSingIn');
				const routerUrl = targetUrlAfterSingIn.split('/');
				this.router.navigate(routerUrl, { replaceUrl: true });
				return true;
			}
		}
		return false;
	}

	loginByAuthHeader(accessToken: string) {
		if (this.isBrowser && accessToken) {
			const decoded: JWTObject = this.oktaAuth.token.decode(accessToken);
			const accessTokenObj: AccessToken = {
				'accessToken': accessToken,
				'claims': decoded.payload,
				'expiresAt': decoded.payload.exp - 163,
				'tokenType': 'Bearer',
				'scopes': ['openid', 'email', 'profile', 'okta.myAccount.email.manage'],
				'authorizeUrl': `${environment.okta.oktaRestUrl}/oauth2/${environment.okta.iss}/v1/authorize`,
				'userinfoUrl': `${environment.okta.oktaRestUrl}/oauth2/${environment.okta.iss}/v1/userinfo`
			};
			this.oktaAuth.tokenManager.add('accessToken', accessTokenObj);
		}
	}

	private generateRandomStateString(length = 64) {
		const randomCharset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let random = '';
		for (let c = 0, cl = randomCharset.length; c < length; ++c) {
			random += randomCharset[Math.floor(Math.random() * cl)];
		}
		return random;
	}

	setLoginData(flow = 'login', keeploggedin = true, redirectUri = '', codeChallenge = '', queryParams = {}) {
		const storage = this.oktaAuth.storageManager.getTokenStorage({ storageType: 'sessionStorage' });
		if (this.isWebview && this.isBrowser) {
			storage.setItem(
				'okta-webview-login',
				JSON.stringify({
					flow: flow,
					keeploggedin: keeploggedin,
					redirectUri: redirectUri,
					codeChallenge: codeChallenge,
					queryParams: queryParams
				})
			);
		} else {
			storage.removeItem('okta-webview-login');
		}
	}

	getLoginData() {
		const storage = this.oktaAuth.storageManager.getTokenStorage({ storageType: 'sessionStorage' });
		if (this.isWebview && this.isBrowser) {
			try {
				const loginData = JSON.parse(storage.getItem('okta-webview-login'));
				this.removeLoginData();
				return loginData;
			} catch (e) {
				return null;
			}
		} else {
			return null;
		}
	}

	removeLoginData() {
		if (this.isWebview && this.isBrowser) {
			const storage = this.oktaAuth.storageManager.getTokenStorage({ storageType: 'sessionStorage' });
			storage.removeItem('okta-webview-login');
		}
	}

	signIn(username: string, password: string, keeploggedin = false, codeChallenge = null, redirectUri = null, queryParams = {}): Observable<any> {
		return from(this.oktaAuth.signInWithCredentials({ username, password })).pipe(
			withLatestFrom(
				this.lstore.pipe(
					select(getWorkingLanguage),
					map((language: Language) => language.code)
				)
			),
			switchMap(([oktaTransaction, language]: [AuthnTransaction, string]) => {
				if (oktaTransaction.status === 'SUCCESS') {
					this.setLoginData('login', keeploggedin, redirectUri, codeChallenge, queryParams);
					return from(
						this.oktaAuth.token.getWithRedirect({
							sessionToken: oktaTransaction.sessionToken,
							scopes: ['openid', 'email', 'profile', 'offline_access', 'okta.myAccount.email.manage', ...(keeploggedin ? ['remember-me'] : [])],
							responseType: 'id_token',
							redirectUri: `${this.window.location.origin}/${language}/account/callback`
						})
					);
				} else {
					throw new Error('Authentication error');
				}
			}),
			catchError(this.handleError)
		);
	}

	signInFromSession(keeploggedin = false, codeChallenge = null, redirectUri = null) {
		if (codeChallenge) {
			const restUrl = `${environment.okta.oktaRestUrl}/oauth2/${environment.okta.iss}/v1/authorize`;
			const state = this.generateRandomStateString();
			const queryParams = {
				client_id: this.clientId,
				code_challenge: codeChallenge,
				code_challenge_method: 'S256',
				redirect_uri: redirectUri,
				response_type: 'code',
				state: state,
				scope: ['openid', 'email', 'profile', 'offline_access', 'okta.myAccount.email.manage', ...(keeploggedin ? ['remember-me'] : [])].join(' ')
			};
			this.oktaAuth.token.getWithRedirect._setLocation(restUrl + toQueryString(queryParams));
		}
	}

	sendForgotPasswordEmail(email: string): Observable<AuthnTransaction> {
		return from(
			this.oktaAuth.forgotPassword({
				username: email,
				factorType: 'EMAIL'
			})
		).pipe(catchError(this.handleError));
	}

	verifyPasswordRecoveryToken(recoveryToken: string): Observable<string> {
		if (this.isBrowser) {
			return from(this.oktaAuth.verifyRecoveryToken({ recoveryToken: recoveryToken })).pipe(
				map((transaction: AuthnTransaction) => {
					if (transaction.status === 'PASSWORD_RESET') {
						return (transaction as any)?.data?.stateToken;
					} else {
						throwError('Token verification error');
					}
				}),
				catchError(this.handleError)
			);
		}
		return of(null);
	}

	renewToken() {
		if (this.isBrowser) {
			return from(this.oktaAuth.tokenManager.renew('accessToken')).pipe(catchError(() => of(null)));
		}
		return of(null);
	}

	logout() {
		this.lstore
			.pipe(
				select(getWorkingLanguage),
				map((language: Language) => language.code),
				switchMap((language: string) => from(this.oktaAuth.signOut({ postLogoutRedirectUri: `${this.window.location.origin}/${language}` })))
			)
			.subscribe();
	}

	logoutSilent() {
		if (this.isBrowser) {
			from(this.oktaAuth.revokeRefreshToken()).pipe(take(1)).subscribe();
			from(this.oktaAuth.revokeAccessToken()).pipe(take(1)).subscribe();
			this.oktaAuth.tokenManager.clear();
			this.oktaAuth.authStateManager.updateAuthState();
		}
	}

	setProduct(product: 'web' | 'fantasy' | 'bundesliga-app' = 'web') {
		this.product = ['web', 'fantasy', 'bundesliga-app'].includes(product) ? product : 'web';
	}

	private socialSignOn(codeChallenge = null, redirectUri = null, idp = '', idp_scope = '', prompt = '', queryParams = {}) {
		return this.lstore
			.pipe(
				select(getWorkingLanguage),
				map((language: Language) => language.code)
			)
			.pipe(
				switchMap((language: string) => {
					this.setLoginData('login', true, redirectUri, codeChallenge, queryParams);
					const defaultRedirectUri = `${this.window.location.origin}/${language}/account/callback`;
					return from(
						this.oktaAuth.token.getWithRedirect({
							redirectUri: this.product && (this.product === 'bundesliga-app' || this.product === 'fantasy') ? `${defaultRedirectUri}/${this.product}` : defaultRedirectUri,
							scopes: ['openid', 'email', 'profile', 'offline_access'],
							idpScope: idp_scope,
							responseType: 'id_token',
							idp: idp,
							prompt: prompt
						})
					);
				}),
				catchError(this.handleError)
			);
	}

	loginWithGoogle(codeChallenge = null, redirectUri = null, queryParams = {}) {
		const idp_scope = 'openid email profile';
		const prompt = 'consent login select_account';
		const idp = environment.okta.idp.google;
		return this.socialSignOn(codeChallenge, redirectUri, idp, idp_scope, prompt, queryParams);
	}

	loginWithFacebook(codeChallenge = null, redirectUri = null, queryParams = {}) {
		const idp_scope = 'public_profile email';
		const prompt = 'consent login';
		const idp = environment.okta.idp.facebook;
		return this.socialSignOn(codeChallenge, redirectUri, idp, idp_scope, prompt, queryParams);
	}

	loginWithApple(codeChallenge = null, redirectUri = null, queryParams = {}) {
		const idp_scope = 'name email openid';
		const prompt = 'consent login';
		const idp = environment.okta.idp.apple;
		return this.socialSignOn(codeChallenge, redirectUri, idp, idp_scope, prompt, queryParams);
	}

	loginWithX(codeChallenge = null, redirectUri = null, queryParams = {}) {
		const idp_scope = 'openid email profile';
		const prompt = 'consent login select_account';
		const idp = environment.okta.idp.x;
		return this.socialSignOn(codeChallenge, redirectUri, idp, idp_scope, prompt, queryParams);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			return throwError('Connection error');
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			return throwError(error);
		}
	}

	ngOnDestroy(): void {
		if (this.isBrowser && this.oktaAuth && this.oktaAuth.authStateManager) {
			this.oktaAuth.authStateManager.unsubscribe();
			this.oktaAuth.stop();
		}
	}
}
